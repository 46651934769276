.contact-background {
  padding: 20px 0;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Agrega sombra al banner */
}

.contact-div {
  padding: 20px;
  background-color: white;
  box-shadow: 0px 0px 20px #e9e9e9;
  display: flex;
  justify-content: space-between;
  border-radius: 10px; /* Agrega bordes redondeados al formulario */
}

.contact-form-div {
  width: 100%;
  margin-right: 0;
  padding: 20px; /* Añade un espaciado interno al formulario */
}

.contact-info-div {
  margin-top: 20px;
  margin-left: 0;
  width: 100%;
}

.contact-field {
  border: 1px solid rgb(184, 184, 184);
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  color: gray;
  margin-bottom: 10px;
  width: 100%;
  background-color: #f5f5f5; /* Agrega un fondo para resaltar los campos */
  transition: background-color 0.2s ease;
}

.contact-field:hover {
  background-color: #e5e5e5; /* Cambia el color de fondo al pasar el cursor */
}

.contact-field-input {
  width: 100%;
  font-size: 16px;
}

.contact-field-textarea {
  resize: none;
  width: 100%;
  height: 120px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.invalid {
  border: 1px #a51a1a solid;
  background-color: rgba(255, 137, 137, 0.068);
}

.contact-info-subtitles {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.contact-icons {
  font-size: 35px;
  vertical-align: -8px;
  margin-right: 10px;
  color: #8F0100;
}

.contact-info-text {
  margin-left: 0;
  color: rgb(68, 68, 68);
}

.contact-form-submit {
  padding: 10px;
  width: 100%;
  border: none;
  color: white;
  background-color: #8F0100;
  border-radius: 5px;
  cursor: pointer; /* Agrega un cursor al botón */
}

.contact-form-submit:focus {
  outline: none;
  background-color: #a51a1adc;
}

@media (max-width: 756px) {
  .contact-div {
    padding: 10px;
    flex-direction: column;
    word-wrap: none;
  }
  .contact-background {
    padding: 20px 10px;
  }
  .contact-form-div {
    width: 100%;
  }
}
