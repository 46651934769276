.inm-placement {
  display: flex;
  margin: 100px 100px;
}

.inm-div {
  background-color: #e9e9e994;
  margin-bottom: -100px;
  padding-bottom: 100px;
}

.inm-spacer {
  height: 70px;
  width: 100%;
}

.inm-title-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 40px 100px;
  flex-wrap: wrap;
}

.inm-pretitle {
  margin-bottom: -7px;
  font-size: 13px;
  font-weight: 400;
}

.inm-title {
  font-size: 2.2em;
  font-weight: 400;
}

.inm-title-left {
  margin: 10px 0;
}

.inm-subtitle {
  font-size: 1em;
  font-weight: 400;
}

.inm-title-right {
  border-left: 1px solid rgb(189, 189, 189);
  padding-left: 16px;
}

.inm-desc-text {
  white-space: pre-wrap;
}

.inm-type {
  color: #8F0100;
  font-size: 1.6em;
  font-weight: 400;
}

.inm-price {
  font-size: 1.3em;
}

.inm-slider {
  margin: 0 100px;
}

.inm-body {
  flex-grow: 1;
  margin-right: 50px;
  padding: 50px;
  border: 1px solid rgb(204, 204, 204);
  box-shadow: 0px 0px 20px #e9e9e9;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.inm-body-char {
  display: flex;
  flex-wrap: wrap;
}

.inm-body-char-div {
  margin: 20px 55px 20px 0;
}

.inm-char-icon {
  margin-right: 4px;
}

.inm-char-number {
  margin-left: 4px;
  font-size: x-large;
}

.inm-char-icons {
  color: #8F0100;
  vertical-align: -5px;
  margin-right: 6px;
  font-size: 26px;
}

.inm-char-p {
  font-size: larger;
  white-space: nowrap;
}
.inm-body-att {
  margin: 50px 0;
  display: flex;
  flex-wrap: wrap;
}

.inm-att-title {
  margin-top: 50px;
  font-weight: 400;
  font-size: 2em;
}

.inm-body-att-div {
  height: 50px;
  width: 50%;
  max-width: 50%;
}

.inm-desc-title {
  font-size: 2em;
  font-weight: 400;
  margin: 30px 0 20px;
}

.inm-feat-icons {
  vertical-align: -3px;
  margin-right: 10px;
}

.inm-feat-true {
  color: green;
}

.inm-feat-false {
  color: #8F0100;
}

.inm-sidebar {
  display: flex;
  border: 1px solid rgb(204, 204, 204);
  box-shadow: 0px 0px 20px #e9e9e9;
  align-self: flex-start;
}

/* Estilos para pantallas grandes (desktop) */
.inm-agent-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5; /* Color de fondo */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombreado */
  border-radius: 10px; /* Bordes redondeados para resaltar */
  margin: 10px; /* Espaciado exterior */
}

.inm-agent-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #8F0100;; /* Color de fondo */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombreado */
  border-radius: 10px; /* Bordes redondeados para resaltar */
  margin: 10px; /* Espaciado exterior */
}

/* Estilos para pantallas más pequeñas (móviles) */
@media (max-width: 480px) {
  .inm-agent-contact, .inm-agent-info {
    padding: 10px; /* Reduzca el relleno para dispositivos móviles */
  }
}


.inm-agent-img {
  width: 150px;
  height: 150px;
  margin-top: -40px;
  margin-bottom: 20px;
}

.inm-agent-contact-info {
  text-align: center;
}



@media (max-width: 438px) {
  .inm-agent-info {
    flex-wrap: wrap;
  }
}


@media (max-width: 768px) {
  .inm-title-div {
    margin: 10px;
  }
  .inm-slider {
    margin: 0;
  }

  .inm-placement {
    flex-direction: column;
    margin: 10px;
  }

  .inm-body {
    margin: 0;
  }

  .inm-sidebar {
    margin-top: 40px;
    width: 100%;
    justify-content: center;
  }

  .inm-agent-info {
    flex-direction: row;
  }

  .inm-agent-contact {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
  }

  .inm-agent-img {
    margin: 0;
  }

 .inm-agent-contact-container {
  display: flex;
  justify-content: center; /* Centrar horizontalmente los elementos */
  flex-wrap: wrap; /* Envolver elementos cuando sea necesario */
  margin-top: 20px; /* Espacio en la parte superior */
}

.inm-agent-contact-info {
  margin-right: 20px; /* Separación entre elementos */
  margin-bottom: 20px; /* Espacio en la parte inferior */
}

.inm-title-right {
  flex-grow: 1; /* Ocupar espacio disponible */
  border: none; /* Eliminar el borde */
  padding: 0; /* Eliminar el relleno */
  text-align: right; /* Alinear el contenido a la derecha */
}

}

@media (min-width: 769px) and (max-width: 1139px) {
  .inm-title-div {
    margin-top: 0px;
    margin-right: 30px;
    margin-left: 30px;
  }
  .inm-title-right {
    border: none;
    position: absolute;
    top: 120px;
    right: 40px;
    text-align: center;
    z-index: 1;
  }
  .inm-slider {
    margin-right: 30px;
    margin-left: 30px;
  }
  .inm-placement {
    flex-direction: column;
  }

  .inm-body {
    margin: 0;
  }

  .inm-sidebar {
    margin-top: 40px;
    width: 100%;
    justify-content: center;
  }

  .inm-agent-info {
    flex-direction: row;
  }

  .inm-agent-contact {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
  }

  .inm-agent-img {
    margin: 0;
  }

  .inm-agent-contact-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px 0;
  }

  .inm-agent-contact-info {
    margin-right: 20px;
  }
}

.c-loader {
  margin: 20% auto;
  width: 100px;
  height: 100px;
  border: 3px solid #e5e5e5;
  border-top-color: #8F0100;
  display: block;
  animation: is-rotating 1s infinite;
  border-radius: 50%;
}

@keyframes is-rotating {
  to {
    transform: rotate(1turn);
  }
}
