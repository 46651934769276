/* Base Styles */
.publish-div {
  background-color: #f5f5f5;
  font-family: Helvetica, Arial, sans-serif;
  color: #333;
  padding: 20px;
  max-width: 100%; /* Limita el ancho en escritorio */
  margin: 0 auto; /* Centra el div en escritorio */
}

.publish-form {
  padding: 20px;
  background-color: white;
  box-shadow: 0px 0px 10px #e9e9e9;
  border: 1px solid #ccc;
  border-radius: 4px; /* Agregamos bordes redondeados */
}

.publish-form input[type="text"],
.publish-form textarea {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  color: #333;
  margin: 10px 0;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.publish-form input[type="text"]:focus,
.publish-form textarea:focus {
  border-color: #8F0100;;
  box-shadow: 0px 0px 5px #8f0000a8;
}

.publish-form label {
  font-size: 18px;
  display: block;
  margin-bottom: 5px;
  transition: color 0.3s;
}

.publish-form label:focus {
  color: #8F0100;
}


/* Botón de envío */
.publish-form button {
  background-color: #8F0100;
  color: #fff;
  border: none;
  padding: 15px 30px;
  font-size: 18px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s, transform 0.2s;
}

.publish-form button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}
.publish-form .publish-form-images-container:hover {
  transform: scale(1.05);
  transition: transform 0.2s;
}
.publish-form .delete-image-icon:hover {
  transform: scale(1.2);
  transition: transform 0.2s;
}

/* Responsividad */
@media (max-width: 756px) {
  .publish-div {
   
    margin: 0px auto 0; /* Agrega margen superior en dispositivos móviles */
  }

  .publish-form {
    margin: 10px auto;
    padding: 10px;
    box-shadow: 0px 0px 10px #e9e9e9;
    border: 1px solid #ccc;
  }

  .publish-form label {
    font-size: 16px;
  }

  /* Redefinimos el ancho de los campos de entrada para dispositivos móviles */
  .publish-form input[type="text"],
  .publish-form textarea {
    width: 100%;
  }

  /* Ajustamos los estilos de los checkboxes para dispositivos móviles */
  .publish-form .publish-form-att-div {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Espacio entre los checkboxes */
  }
}

/* Estilos adicionales para una experiencia mejorada */
.publish-form .publish-form-input {
  width: 100%;
  display: block;
}

.publish-form .publish-form-mercadolibre-url {
  width: 100%;
  margin-top: 10px;
}

.publish-form .publish-form-title-input,
.publish-form .publish-form-price-input,
.publish-form .publish-form-description-textarea {
  width: 100%;
  padding: 8px;
  font-size: 14px;
}

.publish-form .publish-form-title-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.publish-form .publish-form-price-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.publish-form .publish-form-location-div {
  display: flex;
  flex-direction: column;
}

.publish-form .publish-form-location-input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
}
.publish-form-att-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Columnas adaptables, con un ancho mínimo de 150px */
  gap: 10px;
}

/* Estilos para cada elemento de verificación */
.publish-form-att-div {
  display: flex;
  align-items: center; /* Centrar elementos verticalmente */
  gap: 5px; /* Espacio entre elementos */
}

.publish-form-att-label {
  cursor: pointer;
  font-size: 16px;
}


/* Organizamos los campos de verificación en tres columnas para escritorio */
@media (min-width: 757px) {
  .publish-form-att-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); /* Columnas aún más adaptables */
  }
  .page-title-banner {
    display: none;
  }
  .publish-form-att-div {
    flex-direction: column; /* Apilar elementos verticalmente en dispositivos móviles */
    align-items: flex-start; /* Alinear elementos a la izquierda */
  }

  .publish-form-att-label {
    font-size: 14px; /* Tamaño de fuente más pequeño para dispositivos móviles */
  }

  /* Agregamos estilos para que las etiquetas y los checkboxes coincidan */
 
  /* Estilo para los checkboxes marcados */
  .publish-form .publish-form-att-input:checked + .publish-form-att-label::before {
    background-color: #007BFF; 
    /* Color de fondo cuando está marcado */
  }
}

.publish-form .publish-form-images-ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.publish-form .publish-form-images-container {
  position: relative;
  margin-bottom: 10px;
}
.publish-form .publish-form-images-container:hover {
  transform: scale(1.05);
  transition: transform 0.2s;
}
.publish-form .delete-image-icon:hover {
  transform: scale(1.2);
  transition: transform 0.2s;
}
.publish-form .delete-image-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 2px;
  color: #ff0000;
  font-size: 18px;
}
