/* Insta.css */
.contenedor {
    display: flex;
    flex-wrap: nowrap; /* Evita que las publicaciones se envuelvan automáticamente */
    justify-content: center; /* Alinea las publicaciones en el centro de la pantalla */
    align-items: center; /* Alinea verticalmente el contenido */
    overflow-x: auto; /* Agrega una barra de desplazamiento horizontal en caso de desbordamiento */
    max-width: 100%; /* Utiliza el ancho completo de la pantalla */
  }
  
  .instagram-embed {
    max-width: calc(33.33% - 10px); /* Ajusta el ancho de las publicaciones para mostrar tres columnas con un espacio de 10px entre ellas */
    box-sizing: border-box;
    margin-right: 10px; /* Agrega un espacio de 10px a la derecha de cada publicación */
    margin-bottom: 10px; /* Agrega un espacio de 10px debajo de cada publicación */
  }
  
  .instagram-media {
    background: #FFF;
    border: 0;
    border-radius: 3px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5), 0 1px 10px 0 rgba(0, 0, 0, 0.15);
    width: 100%;
    padding: 0;
  }
  
  /* Estilo adicional para hacerlo más responsive */
  @media (max-width: 768px) {
    .contenedor {
      flex-direction: column;
      align-items: center;
      overflow-x: hidden; /* Oculta la barra de desplazamiento en dispositivos móviles */
    }
    .instagram-embed {
      max-width: 100%; /* Hacer que las publicaciones ocupen el 100% del ancho en dispositivos móviles */
      margin-right: 0; /* Elimina el margen derecho en dispositivos móviles */
      margin-bottom: 10px; /* Agrega un espacio de 10px debajo de cada publicación en dispositivos móviles */
    }
  }
  