/* Estilos base */
.log-div {
  position: fixed; /* Puedes usar fixed en lugar de absolute para mantenerlo en el centro al hacer scroll */
  z-index: 20;
  top: 50%; /* Centra verticalmente */
   /* Centra horizontalmente */
  transform: translate(-50%, -50%);
  width: 100%;
}


.log-form {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px; /* Limita el ancho en pantallas más grandes */
}

.log-input {
  margin: 10px 0;
  padding: 10px; /* Aumenta el espacio de relleno para dispositivos táctiles */
  border: 2px solid rgb(68, 68, 68);
  border-radius: 5px;
  transition: border-color 200ms ease;
  font-size: 16px; /* Aumenta el tamaño del texto */
}

.log-input:invalid {
  border-color: red;
}

.log-input:focus {
  border-color: rgb(68, 68, 68);
}

.log-title {
  color: rgb(68, 68, 68);
  text-align: center;
  font-size: 24px;
  margin: 10px 0;
}

.log-button {
  background-color: #8F0100;
  border: none;
  color: white;
  font-size: 18px;
  border-radius: 5px;
  padding: 12px 20px;
  margin: 20px 0;
  transition: background 200ms ease;
  cursor: pointer;
  text-align: center; /* Centra el texto del botón */
}

.log-button:hover {
  background-color: #8F0100;
}

.log-email {
  color: rgb(134, 35, 35);
  font-weight: 600;
}

.log-dashboard {
  background-color: #8F0100;
  border: none;
  color: white;
  font-size: 18px;
  border-radius: 5px;
  padding: 12px 20px;
  margin: 10px 0;
  text-align: center;
  text-decoration: none;
}

.log-dashboard:hover {
  background-color: rgb(100, 25, 25);
}

/* Estilos para dispositivos móviles */
@media (max-width: 768px) {
  .log-div {
    position: fixed; /* Puedes usar fixed en lugar de absolute para mantenerlo en el centro al hacer scroll */
    z-index: 20;
    top: 60%; /* Centra verticalmente */
    left: 50% ;/* Centra horizontalmente */
    transform: translate(-50%, -50%);
    width: 93%;
    margin-top: 5px;height: 600px; /* Agrega un margen superior */
  }
  

  .log-form {
    padding: 16px; /* Aumenta el relleno para una apariencia más grande */
  }

  .log-title {
    font-size: 22px; /* Reduzca un poco el tamaño del título */
  }

  .log-input {
    padding: 12px; /* Aumenta el relleno en campos de entrada */
    font-size: 14px; /* Reduce el tamaño del texto para adaptarse a la pantalla más pequeña */
  }

  .log-button {
    padding: 14px 20px; /* Aumenta el relleno del botón */
    font-size: 16px; /* Reduzca el tamaño del texto del botón */
  }
}
