/* Grid-area Naming */

.menu-logo {
  grid-area: mlogo;
  justify-self: end; /* Centra el logotipo más a la derecha */
}

.menu-image {
  grid-area: mlogo;
  width: 50%; /* Reduzco el ancho del logotipo al 50% */
}
.menu-dropdown {
  grid-area: mdrop;
}

.menu-navbar {
  grid-area: mnav;
}

.menu-phone {
  grid-area: mphone;
 
}

.menu-login {
  grid-area: mlog;
}

.menu-publish {
  grid-area: mpub;
}

.menu-main {
  display: grid;
}

/* General style */

.menu-list {
  list-style: none;
  line-break: loose;
  padding: 1em 1.5vw;
  cursor: pointer;
  font-weight: 500;
  transition: 0.2s all linear;
  font-size: 0.9em;
  text-shadow: rgb(10, 10, 10) 1px 1px 10px;
  white-space: nowrap;
}

.menu-link {
  text-decoration: none;
  color: white;
}

.menu-main {
  grid-template-areas:
    "mlogo mnav mphone mlog mpub";
  z-index: 1;
  position: absolute;
  top: 0;
  color: white;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 10%, rgb(5, 5, 5) 100%);
  height: 100px;
  width: 100%;
  max-width: 100vw;
  display: grid;
  align-items: center;
}

.menu-image img {
  max-width: 100%; /* Asegura que la imagen no exceda el ancho de su contenedor */
  height: auto; /* Mantén la proporción de la imagen */
  display: block;
  margin: 0 auto; /* Centra la imagen horizontalmente */
}

.menu-logo {
  grid-area: mlogo;
  justify-self: start; /* Ajusta la alineación del logo */
  margin: 5% 5% 0 5%; /* Margen izquierdo y derecho como porcentaje del ancho de la pantalla */
  width: auto; /* Permite que el logo se reduzca */
  max-width: 90%; /* Establece un ancho máximo para el logo en porcentaje (ajusta según tus necesidades) */
}


.menu-list {
  font-weight: 300;
  font-size: 1.2em;
  text-shadow: rgb(10, 10, 10) 1px 1px 10px;
}

.menu-navbar {
  display: flex;
}

.menu-publish-p-a {
  background-color: #8F0100;
   color: rgb(0, 0, 0);
  border-radius: 5px;
  padding: 5px 15px 7px;
}

.menu-hover-efect:hover {
  background: #8F0100;
 color: white;
  text-shadow: none;
}

.menu-icon-profile {
  font-size: 40px;
  padding: 0 -20px 0 20px;
  margin: 0 25px 0 0;
  vertical-align: -8px;
}

.menu-phone-a {
  text-decoration: none;
  color: rgb(0, 0, 0);
}

.menu-icon-phone {
  vertical-align: -4px;
  margin: 0 4px 0 0;
  font-size: 1.2em;
  justify-content: center;
  color: rgb(0, 0, 0);
}

.menu-phone-p {
  margin: 0 20px 0 0;
  flex-wrap: nowrap;
}

/* Desktop only style */

@media (min-width: 1200px) {
  .menu-dropdown {
    display: none;
  }

  .menu-main {
    justify-content: center;
  }
}

@media (max-width: 1500px) and (min-width: 1200px) {
  .menu-list {
    font-size: 1.2vw;
  }
}

/* Tablet only style */

@media (max-width: 1200px) and (min-width: 756px) {
  .menu-spacer {
    margin-bottom: 86px;
  }

  .menu-main {
    grid-template-areas: "mlogo mphone mlog mpub mdrop";
    grid-template-columns: auto 173px 65px 93px 80px;
    height: 86px;
    background: white;
    color: white;
  }

  .menu-publish {
    margin: 0 8px 0 0;
  }

  .menu-logo {
    justify-self: start;
    margin: 0 0 0 10px;
  }

  .menu-dropdown,
  .menu-publish,
  .menu-login,
  .menu-phone {
    justify-self: end;
  }

  .menu-navbar {
    display: none;
  }

  .dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 200px;
    right: 0;
    border-radius: 0 0 0 8px;
    z-index: 4;
    overflow: hidden;
    background-color: rgb(70, 70, 70);
    color: rgb(190, 190, 190);
    top: 86px;
  }
}

/* Mobile styles */

@media (max-width: 756px) {
  .menu-spacer {
    margin-bottom: 125px;
  }

  .menu-main {
    grid-template-areas:
      "mlog mlogo mdrop"
      "mphone mphone mpub";
    grid-template-rows: 70px 55px;
    grid-template-columns: 60px auto 90px;
    background: white;
    color: white;
    height: auto;
  }

  .menu-logo {
    justify-self: center;
  }

  .menu-navbar {
    display: none;
  }

  .menu-publish {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    list-style: none;
    background-color: #8F0100;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-publish-p {
    font-size: 1.1em;
  }

  .menu-dropdown {
    justify-self: end;
  }

  .menu-login {
    margin: 0 0 0 20px;
  }

  .menu-phone {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
  }

  .dropdown {
    position: absolute;
    top: 70px;
    display: flex;
    flex-direction: column;
    width: 200px;
    right: 0;
    border-radius: 0 0 0 8px;
    z-index: 4;
    overflow: hidden;
    background-color: rgb(70, 70, 70);
    width: 100%;
    text-align: center;
  }




  .dropdown-item {
    border-top: 1px solid rgba(255, 255, 255, 0.555);
  }
}

@media screen and (device-aspect-ratio: 2/3) {

  .menu-spacer {
    margin-bottom: 125px;
  }

  .menu-main {
    grid-template-areas:
      "mlog mlogo mdrop"
      "mphone mphone mpub";
    grid-template-rows: 70px 55px;
    grid-template-columns: 60px auto 90px;
    background: white;
    color: white;
    height: auto;
  }

  .menu-logo {
    justify-self: center;
  }

  .menu-navbar {
    display: none;
  }

  .menu-publish {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    list-style: none;
    background-color: #8F0100;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-publish-p {
    font-size: 1.1em;
  }

  .menu-dropdown {
    justify-self: end;
  }

  .menu-login {
    margin: 0 0 0 20px;
  }

  .menu-phone {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
  }

  .dropdown {
    position: absolute;
    top: 70px;
    display: flex;
    flex-direction: column;
    width: 200px;
    right: 0;
    border-radius: 0 0 0 8px;
    z-index: 4;
    overflow: hidden;
    background-color: rgb(70, 70, 70);
    width: 100%;
    text-align: center;
  }




  .dropdown-item {
    border-top: 1px solid rgba(255, 255, 255, 0.555);
  }
}

@media screen and (device-aspect-ratio: 40/71) {

  .menu-spacer {
    margin-bottom: 125px;
  }

  .menu-main {
    grid-template-areas:
      "mlog mlogo mdrop"
      "mphone mphone mpub";
    grid-template-rows: 70px 55px;
    grid-template-columns: 60px auto 90px;
     background:white;
    color: white;
    height: auto;
  }

  .menu-logo {
    justify-self: center;
  }

  .menu-navbar {
    display: none;
  }

  .menu-publish {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    list-style: none;
    background-color: #8F0100;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-publish-p {
    font-size: 1.1em;
  }

  .menu-dropdown {
    justify-self: end;
  }

  .menu-login {
    margin: 0 0 0 20px;
  }

  .menu-phone {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
  }

  .dropdown {
    position: absolute;
    top: 70px;
    display: flex;
    flex-direction: column;
    width: 200px;
    right: 0;
    border-radius: 0 0 0 8px;
    z-index: 4;
    overflow: hidden;
    background-color: rgb(70, 70, 70);
    width: 100%;
    text-align: center;
  }




  .dropdown-item {
    border-top: 1px solid rgba(255, 255, 255, 0.555);
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation:portrait) {
  .menu-spacer {
    margin-bottom: 125px;
  }

  .menu-main {
    grid-template-areas:
      "mlog mlogo mdrop"
      "mphone mphone mpub";
    grid-template-rows: 70px 55px;
    grid-template-columns: 60px auto 90px;
    background:white;
    color: white;
    height: auto;
  }

  .menu-logo {
    justify-self: center;
  }

  .menu-navbar {
    display: none;
  }

  .menu-publish {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    list-style: none;
    background-color: #8F0100;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-publish-p {
    font-size: 1.1em;
  }

  .menu-dropdown {
    justify-self: end;
  }

  .menu-login {
    margin: 0 0 0 20px;
  }

  .menu-phone {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
  }

  .dropdown {
    position: absolute;
    top: 70px;
    display: flex;
    flex-direction: column;
    width: 200px;
    right: 0;
    border-radius: 0 0 0 8px;
    z-index: 4;
    overflow: hidden;
    background-color: rgb(70, 70, 70);
    width: 100%;
    text-align: center;
  }




  .dropdown-item {
    border-top: 1px solid rgba(255, 255, 255, 0.555);
  }
}

@media only screen and (max-device-width: 1136px) and (min-device-width: 960px) and (max-device-height: 640px) and (min-device-height: 560px),
only screen and (max-device-height: 1136px) and (min-device-height: 960px) and (max-device-width: 640px) and (min-device-width: 560px) {
  .menu-spacer {
    margin-bottom: 125px;
  }

  .menu-main {
    grid-template-areas:
      "mlog mlogo mdrop"
      "mphone mphone mpub";
    grid-template-rows: 70px 55px;
    grid-template-columns: 60px auto 90px;
    background: white;
    color: white;
    height: auto;
  }

  .menu-logo {
    justify-self: center;
  }

  .menu-navbar {
    display: none;
  }

  .menu-publish {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    list-style: none;
    background-color: #8F0100;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-publish-p {
    font-size: 1.1em;
  }

  .menu-dropdown {
    justify-self: end;
  }

  .menu-login {
    margin: 0 0 0 20px;
  }

  .menu-phone {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
  }

  .dropdown {
    position: absolute;
    top: 70px;
    display: flex;
    flex-direction: column;
    width: 200px;
    right: 0;
    border-radius: 0 0 0 8px;
    z-index: 4;
    overflow: hidden;
    background-color: rgb(70, 70, 70);
    width: 100%;
    text-align: center;
  }




  .dropdown-item {
    border-top: 1px solid rgba(255, 255, 255, 0.555);
  }
}

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {

  /* Styles */
  .menu-spacer {
    margin-bottom: 125px;
  }

  .menu-main {
    grid-template-areas:
      "mlog mlogo mdrop"
      "mphone mphone mpub";
    grid-template-rows: 70px 55px;
    grid-template-columns: 60px auto 90px;
    background: white;
    color: white;
    height: auto;
  }

  .menu-logo {
    justify-self: center;
  }

  .menu-navbar {
    display: none;
  }

  .menu-publish {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    list-style: none;
    background-color: #8F0100;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-publish-p {
    font-size: 1.1em;
  }

  .menu-dropdown {
    justify-self: end;
  }

  .menu-login {
    margin: 0 0 0 20px;
  }

  .menu-phone {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
  }

  .dropdown {
    position: absolute;
    top: 70px;
    display: flex;
    flex-direction: column;
    width: 200px;
    right: 0;
    border-radius: 0 0 0 8px;
    z-index: 4;
    overflow: hidden;
    background-color: rgb(70, 70, 70);
    width: 100%;
    text-align: center;
  }




  .dropdown-item {
    border-top: 1px solid rgba(255, 255, 255, 0.555);
  }
}

@media only screen and (min-width: 393px) and (max-width: 767px) {

  /* Your Styles... */
  .menu-spacer {
    margin-bottom: 125px;
  }

  .menu-main {
    grid-template-areas:
      "mlog mlogo mdrop"
      "mphone mphone mpub";
    grid-template-rows: 70px 55px;
    grid-template-columns: 60px auto 90px;
    background:white;
    color: white;
    height: auto;
  }

  .menu-logo {
    justify-self: center;
  }

  .menu-navbar {
    display: none;
  }

  .menu-publish {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    list-style: none;
    background-color: #8F0100;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-publish-p {
    font-size: 1.1em;
  }

  .menu-dropdown {
    justify-self: end;
  }

  .menu-login {
    margin: 0 0 0 20px;
  }

  .menu-phone {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
  }

  .dropdown {
    position: absolute;
    top: 70px;
    display: flex;
    flex-direction: column;
    width: 200px;
    right: 0;
    border-radius: 0 0 0 8px;
    z-index: 4;
    overflow: hidden;
    background-color: rgb(70, 70, 70);
    width: 100%;
    text-align: center;
  }




  .dropdown-item {
    border-top: 1px solid rgba(255, 255, 255, 0.555);
  }
}

@media only screen and (min-width: 393px) and (max-width: 767px) {

  /* Your Styles... */
  .menu-spacer {
    margin-bottom: 125px;
  }

  .menu-main {
    grid-template-areas:
      "mlog mlogo mdrop"
      "mphone mphone mpub";
    grid-template-rows: 70px 55px;
    grid-template-columns: 60px auto 90px;
    background:white;
    color: white;
    height: auto;
  }

  .menu-logo {
    justify-self: center;
  }

  .menu-navbar {
    display: none;
  }

  .menu-publish {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    list-style: none;
    background-color: #8F0100;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-publish-p {
    font-size: 1.1em;
  }

  .menu-dropdown {
    justify-self: end;
  }

  .menu-login {
    margin: 0 0 0 20px;
  }

  .menu-phone {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
  }

  .dropdown {
    position: absolute;
    top: 70px;
    display: flex;
    flex-direction: column;
    width: 200px;
    right: 0;
    border-radius: 0 0 0 8px;
    z-index: 4;
    overflow: hidden;
    background-color: rgb(70, 70, 70);
    width: 100%;
    text-align: center;
  }




  .dropdown-item {
    border-top: 1px solid rgba(255, 255, 255, 0.555);
  }
}

@media only screen and (min-width: 412px) and (max-width: 767px) {

  /* Your Styles... */
  .menu-spacer {
    margin-bottom: 125px;
  }

  .menu-main {
    grid-template-areas:
      "mlog mlogo mdrop"
      "mphone mphone mpub";
    grid-template-rows: 70px 55px;
    grid-template-columns: 60px auto 90px;
    background: white;
    color: white;
    height: auto;
  }

  .menu-logo {
    justify-self: center;
  }

  .menu-navbar {
    display: none;
  }

  .menu-publish {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    list-style: none;
    background-color: #8F0100;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-publish-p {
    font-size: 1.1em;
  }

  .menu-dropdown {
    justify-self: end;
  }

  .menu-login {
    margin: 0 0 0 20px;
  }

  .menu-phone {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
  }

  .dropdown {
    position: absolute;
    top: 70px;
    display: flex;
    flex-direction: column;
    width: 200px;
    right: 0;
    border-radius: 0 0 0 8px;
    z-index: 4;
    overflow: hidden;
    background-color: rgb(70, 70, 70);
    width: 100%;
    text-align: center;
  }




  .dropdown-item {
    border-top: 1px solid rgba(255, 255, 255, 0.555);
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {

  /* Your Styles... */
  .menu-spacer {
    margin-bottom: 125px;
  }

  .menu-main {
    grid-template-areas:
      "mlog mlogo mdrop"
      "mphone mphone mpub";
    grid-template-rows: 70px 55px;
    grid-template-columns: 60px auto 90px;
    background:white;
    color: white;
    height: auto;
  }

  .menu-logo {
    justify-self: center;
  }

  .menu-navbar {
    display: none;
  }

  .menu-publish {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    list-style: none;
    background-color: #8F0100;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-publish-p {
    font-size: 1.1em;
  }

  .menu-dropdown {
    justify-self: end;
  }

  .menu-login {
    margin: 0 0 0 20px;
  }

  .menu-phone {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
  }

  .dropdown {
    position: absolute;
    top: 70px;
    display: flex;
    flex-direction: column;
    width: 200px;
    right: 0;
    border-radius: 0 0 0 8px;
    z-index: 4;
    overflow: hidden;
    background-color: rgb(70, 70, 70);
    width: 100%;
    text-align: center;
  }




  .dropdown-item {
    border-top: 1px solid rgba(255, 255, 255, 0.555);
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {

  /* Your Styles... */
  .menu-spacer {
    margin-bottom: 125px;
  }

  .menu-main {
    grid-template-areas:
      "mlog mlogo mdrop"
      "mphone mphone mpub";
    grid-template-rows: 70px 55px;
    grid-template-columns: 60px auto 90px;
    background: white;
    color: white;
    height: auto;
  }

  .menu-logo {
    justify-self: center;
  }

  .menu-navbar {
    display: none;
  }

  .menu-publish {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    list-style: none;
    background-color: #8F0100;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-publish-p {
    font-size: 1.1em;
  }

  .menu-dropdown {
    justify-self: end;
  }

  .menu-login {
    margin: 0 0 0 20px;
  }

  .menu-phone {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
  }

  .dropdown {
    position: absolute;
    top: 70px;
    display: flex;
    flex-direction: column;
    width: 200px;
    right: 0;
    border-radius: 0 0 0 8px;
    z-index: 4;
    overflow: hidden;
    background-color: rgb(70, 70, 70);
    width: 100%;
    text-align: center;
  }




  .dropdown-item {
    border-top: 1px solid rgba(255, 255, 255, 0.555);
  }
}

@media only screen and (min-width: 384px) and (max-width: 767px) {

  /* Your Styles... */
  .menu-spacer {
    margin-bottom: 125px;
  }

  .menu-main {
    grid-template-areas:
      "mlog mlogo mdrop"
      "mphone mphone mpub";
    grid-template-rows: 70px 55px;
    grid-template-columns: 60px auto 90px;
    background: white;
    color: white;
    height: auto;
  }

  .menu-logo {
    justify-self: center;
  }

  .menu-navbar {
    display: none;
  }

  .menu-publish {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    list-style: none;
    background-color: #8F0100;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-publish-p {
    font-size: 1.1em;
  }

  .menu-dropdown {
    justify-self: end;
  }

  .menu-login {
    margin: 0 0 0 20px;
  }

  .menu-phone {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
  }

  .dropdown {
    position: absolute;
    top: 70px;
    display: flex;
    flex-direction: column;
    width: 200px;
    right: 0;
    border-radius: 0 0 0 8px;
    z-index: 4;
    overflow: hidden;
    background-color: rgb(70, 70, 70);
    width: 100%;
    text-align: center;
  }




  .dropdown-item {
    border-top: 1px solid rgba(255, 255, 255, 0.555);
  }
}
/* Estilos para vista de celular de 360px */
/* Estilos para vista de celular de 360px */
.dropdown {
  position: absolute;
  top: 70px;
  display: flex;
  flex-direction: column;
  width: 200px;
  right: 0;
  border-radius: 0 0 0 8px;
  z-index: 4;
  overflow: hidden;
  background-color: rgb(70, 70, 70);
  width: 100%;
  text-align: center;
}




.dropdown-item {
  border-top: 1px solid rgba(255, 255, 255, 0.555);
}
.menu-logo {
justify-self: center; /* Centra el logotipo horizontalmente */
}

.menu-image {
width: 60%; 
/* Reduce el ancho del logotipo al 80% del ancho del contenedor */
}

.menu-image img {
display: block;
margin: 0 auto; /* Centra la imagen del logotipo horizontalmente dentro de su contenedor */
}




.menu-logo {
grid-area: mlogo;
justify-self: end; /* Centra el logotipo más a la derecha */
}

.menu-image {
width: 70%;
}

.menu-image img {
display: block;
margin: 0 auto;
}



@media (max-width: 360px) {
  .menu-main {
    grid-template-areas:
      "mlogo mphone mpub"
      "mlog mphone mdrop";
    grid-template-rows: 55px 55px;
    grid-template-columns: auto 90px auto;
  }  .menu-dropdown {
    width: 100%; /* Haz que el menú desplegable ocupe todo el ancho disponible */
  } .menu-logo::after {
    margin-top: 5px; /* Aumenta el espacio entre el logotipo y el texto "INMOBILIARIA" */
  }
  .menu-logo::after {
    content: "INMOBILIARIA"; /* Agrega el texto "INMOBILIARIA" después del logotipo */
    display: block;
    color: #050505;
    text-align: center; /* Centra el texto horizontalmente */
    text-transform: uppercase; /* Convierte el texto a mayúsculas */
    font-size: 1em; /* Tamaño de fuente más pequeño para el texto */
    margin-top: 2px; /* Espacio entre el logotipo y el texto */
  }
  .menu-phone {
    justify-self: end; /* Coloca el menú "Phone" a la derecha */
  }

  .menu-publish {
    order: 3; /* Cambia el orden del menú "Publish" */
  }
}
@media (max-width: 480px) {
  .menu-main {
    grid-template-areas:
      "mlogo mlog"
      "mphone mphone";
    grid-template-columns: auto auto;
  }
  .menu-image img, .menu-logo::after {
    max-width: 100%;
    width: auto;
  }
  .menu-link {
    text-decoration: none;
    color: white;
  }
  .menu-logo {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.4em;
    font-weight: bold;
    margin: 20px 0 0;
    justify-self: start;
    width: auto;
    max-width: 100%;
  }
  .menu-logo::after {
    content: "INMOBILIARIA";
    display: block;
    color: #000000;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.3em;
    font-weight: bold;
    margin: 0;
    grid-area: mlogo;
    font-family: 'Arial', sans-serif;
    justify-self: start;
    width: auto;
    max-width: 100%;
  }
  .menu-login {
    grid-area: mlog;
    position: absolute;
    top: 70px;
    display: flex;
    flex-direction: column; color: #030303;
  }
  .menu-publish {
    display: none;
  }
  .dropdown {
    position: absolute;
    top: 170px;
    display: flex;
    flex-direction: column;
    width: 100%;
    right: 0;
    border-radius: 0 0 0 8px;
    z-index: 4;
    overflow: hidden;
    background-color: #8F0100;
    text-align: center;
  }
  .dropdown a {
    padding: 15px;
    color: #fff;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  .dropdown a:hover {
    background-color: #1b0303;
  }
  .menu-phone {
    justify-self: center;
  }
  .menu-image img {
    max-width: 120%;
    height: auto;
    display: block;
    margin: 10px auto 0;
  }
}



.scrollmenu-div {
  z-index: 30;
  background-color: #333;
  position: fixed;
  top: -85px;
  width: 100%;
  transition: top 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.scrollmenu-image {
  width: 100px;
  height: 30px;
}

.scrollmenu-left {
  display: flex;
  align-items: center;
}

.scrollmenu-link {
  padding: 20px;
  transition: background 200ms ease;
}

.scrollmenu-publish {
  box-sizing: content-box;
  margin: 5px;
  padding: 10px 20px;
  border: 3px solid white;
  transition: background 200ms ease;

}

.scrollmenu-link:hover,
.scrollmenu-publish:hover {
  background-color: white;
  color: #333;
}


.scrollmenu-div a {
  color: #f2f2f2;
  text-align: center;
  text-decoration: none;
  font-size: 17px;
}

@media (max-width: 1200px) {
  .scrollmenu-div {
    display: none;
  }
}

/* ***** navbar burger icon ***** */

#nav-icon span:nth-child(1) {
  top: 0px;
}

#nav-icon span:nth-child(2),
#nav-icon span:nth-child(3) {
  top: 18px;
}

#nav-icon span:nth-child(4) {
  top: 36px;
}

#nav-icon.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon.open span:nth-child(2) {
  transform: rotate(45deg);
}

#nav-icon.open span:nth-child(3) {
  transform: rotate(-45deg);
}

#nav-icon.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon {
  width: 60px;
  height: 45px;
  position: relative;
  margin: 0 10px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  margin-top: 7px;
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 90%;
  background: #8F0100;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}