.card-div {
  width: 100%;
  max-width: 350px;
  margin: 20px;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.card-image {
  width: 100%;
  height: 200px;
  background-size: cover; /* Cambia a 'cover' para que la imagen cubra todo el espacio */
  background-position: center;
}

.card-cont-feat-title {
  font-size: 1rem; /* Aumenta el tamaño de la fuente para una mejor legibilidad */
}
.card-image-type {
  background-color: #007bff; /* Cambia el fondo semi-transparente a un color azul */
  color: white;
  padding: 4px 8px;
  border-radius: 10px; /* Aplica redondeo en todas las esquinas */
  display: inline-block; /* Hace que el fondo abarque solo el contenido del texto */
  margin: 0; /* Elimina el margen por defecto */
  font-weight: bold; /* Hace que el texto sea más audaz */
}


.card-link {
  text-decoration: none;
  color: inherit;
}

.card-content-div {
  padding: 20px;
}

.card-cont-features {
  display: flex;
  flex-wrap: wrap; /* Si hay muchas características, que se envuelvan */
  margin: 10px 0;
}

.card-cont-feat-p {
  font-size: 0.9rem; /* Aumenta el tamaño de la fuente */
  margin: 2px 12px 2px 0; /* Ajusta los márgenes */
}

.card-cont-title {
  font-size: 1.2rem; /* Aumenta el tamaño de la fuente */
  font-weight: 600;
}

.card-cont-ubication {
  font-weight: 500;
  margin-top: 10px;
  color: #999; /* Ajusta el color de texto */
  font-size: 0.9rem; /* Aumenta el tamaño de la fuente */
}

.card-image-counter {
  background-color: rgba(0, 0, 0, 0.7); /* Fondo semi-transparente */
  color: white;
  padding: 4px 8px;
  font-size: 0.9rem; /* Aumenta el tamaño de la fuente */
  border-radius: 4px;
}

.card-div:hover {
  transform: scale(1.05); /* Un poco más de escala en el hover */
}

.card-cont-price-title {
  color: #333; /* Ajusta el color de texto */
  font-size: 1rem; /* Aumenta el tamaño de la fuente */
}

.card-cont-price {
  color: #8F0100; /* Ajusta el color de texto */
  font-weight: 700;
  font-size: 1.5rem; /* Aumenta el tamaño de la fuente */
}

.card-cont-icons {
  color: #555; /* Ajusta el color de texto */
  font-size: 24px; /* Aumenta el tamaño de los íconos */
  margin-right: 8px; /* Espaciado entre los íconos */
}

.card-currency {
  font-size: 0.9rem; /* Aumenta el tamaño de la fuente */
  font-weight: 400;
  color: gray;
}

.card-null {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F0F0F0; /* Ajusta el color de fondo */
}
.whatsapp-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px; /* Ajusta el margen superior según tus necesidades */
}

.whatsapp-container a {
  text-decoration: none;
}

.whatsapp-container img {
  width: 45px; /* Aumenta el ancho de la imagen */
  height: 45px; /* Aumenta la altura de la imagen para mantener la proporción */
  margin-left: 10px; /* Espacio entre el precio y la imagen de WhatsApp */
  transition: transform 0.2s; /* Agrega una transición para el efecto */
}

.whatsapp-container img:hover {
  transform: scale(1.8); /* Efecto de escala al pasar el mouse */
}



@media (max-width: 768px) {
  .card-div {
    width: 90%;
    max-width: none;
  }
  .card-cont-features {
    display: none;
  }
  .card-cont-price-title,
  .card-cont-price {
    text-align: end;
  }
}

@media (max-width: 360px) {
  .card-div {
    width: 80%; /* Ajusta el ancho en pantallas pequeñas */
  }
  .card-cont-features {
    display: none;
  }
  .card-cont-price-title,
  .card-cont-price {
    text-align: end;
  }
}

.hc-main {
  width: 100%;
  margin: 5px;
  border: 1px solid #c4c4c4;
  display: flex;
  flex-direction: row;
}

.hc-image {
  width: 140px;
  /* height: auto; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  margin: 4px 10px 4px 4px;
}

.hc-title{
  font-size: 18px;
  font-weight: 500;
}

.hc-tracks{
  padding: 0 20px;
  align-self: center;
}

.hc-status{
  font-size: 12px;
}

.hc-track-item > label{
  margin-right: 4px;
}

.hc-body{
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-between;
}

.hc-control{
  display: flex;
  flex-direction: column;
  margin: 0 20px 0 0;
}

/* .hc-control-icons{
  
} */

.hc-control-button{
  background: none;
  border: 1px solid rgb(199, 199, 199);
  margin-top: 5px;
  padding: 2px;
  cursor: pointer;
}

.hc-main-paused{
  border: 1px solid rgb(218, 106, 106);
}